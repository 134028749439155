import React from "react";

import "./App.css";

import Face from "./Components/Face";

function App() {
    return (
        <div className="App">
            <h1>Client-side Inference</h1>
            <Face />
        </div>
    );
}

export default App;
