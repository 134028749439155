import React from "react";
import styled from "styled-components";

const LogWrapper = styled.div`
    position: relative;
    top: 600px;
`;

class Log extends React.Component {
    constructor(props) {
        super(props);

        this.logger = React.createRef();
    }

    renderLog = () => {
        if (this.logger.current) {
            this.logger.current.scrollTop = this.logger.current.scrollHeight;
        }

        return this.props.log.map((item) => `\n${item.time}: ${item.info}`);
    };

    render() {
        return (
            <LogWrapper>
                <textarea
                    cols={100}
                    rows={15}
                    ref={this.logger}
                    value={this.renderLog()}
                ></textarea>
            </LogWrapper>
        );
    }
}

export default Log;
