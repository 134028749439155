import React from "react";
import * as faceapi from "face-api.js";
import styled from "styled-components";
import Log from "./Log";

const FaceWrapper = styled.div`
    position: relative;
    #video,
    #canvas {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -360px;
    }
`;

class Face extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.videoRef = React.createRef();
    }

    state = {
        loading: true,
        log: [{ time: Date.now(), info: "Starting" }],
        overlay: false,
    };

    async componentDidMount() {
        await faceapi.loadFaceLandmarkModel("/models");
        await faceapi.loadTinyFaceDetectorModel("/models");
        await faceapi.loadFaceExpressionModel("/models");

        console.log(faceapi.nets);

        const video = this.videoRef.current;

        navigator.getUserMedia(
            { video: {} },
            (stream) => (video.srcObject = stream),
            (err) => console.error(err)
        );

        video.addEventListener("play", () => {
            const canvas = this.canvasRef.current;

            const displaySize = { width: video.width, height: video.height };

            faceapi.matchDimensions(canvas, displaySize);

            setInterval(async () => {
                const p1 = performance.now();

                const detections = await faceapi
                    .detectAllFaces(
                        video,
                        new faceapi.TinyFaceDetectorOptions()
                    )
                    .withFaceLandmarks()
                    .withFaceExpressions();

                const p2 = performance.now();
                console.log(`Inference took: ${p2 - p1} millis`);

                const resizedDetections = faceapi.resizeResults(
                    detections,
                    displaySize
                );

                canvas
                    .getContext("2d")
                    .clearRect(0, 0, canvas.width, canvas.height);

                if (this.state.overlay) {
                    // faceapi.draw.drawDetections(canvas, resizedDetections);
                    faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
                    faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
                }

                if (detections && detections[0]) {
                    // we have a face
                    const expressions = detections[0].expressions.asSortedArray();
                    this.appendLog(`Expression: ${expressions[0].expression}`);
                }
            }, 100);

            this.setState({
                canvas,
                loading: false,
            });
        });
    }

    appendLog = (info) => {
        const logLine = { time: new Date().toLocaleTimeString(), info };
        this.state.log.push(logLine);

        this.setState({
            log: this.state.log,
        });
    };

    toggleOverlay = () => {
        console.log("clicked");
        this.setState((prevState) => ({
            overlay: !prevState.overlay,
        }));
    };

    render() {
        return (
            <React.Fragment>
                <FaceWrapper>
                    <video
                        id="video"
                        width="720"
                        height="560"
                        autoPlay
                        muted
                        ref={this.videoRef}
                    ></video>
                    <canvas id="canvas" ref={this.canvasRef}></canvas>
                    {this.state.loading && <h2>Loading...</h2>}
                </FaceWrapper>
                <button
                    onClick={this.toggleOverlay}
                    style={{ position: "relative" }}
                >
                    Toggle overlay
                </button>
                <Log log={this.state.log}></Log>
            </React.Fragment>
        );
    }
}

export default Face;
